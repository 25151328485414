<template>
	<div class="home">
		<div class="fullview" v-if="loading">
			<div class="loading-spacer"></div>
			<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
		</div>
		<div class="header">
			<h1>測定点一覧</h1>
			<!--
			<div class="search-box">
				<b-form-input class="w-50" id="search" v-model="search_word" type="search"></b-form-input>
				<button type="submit" class="btn btn-primary" @click="onClickSearch">検索</button>
			</div>
			-->
		</div>
		<div class="body">
			<v-data-table :headers="headers" :items="summaryData" :hide-default-footer="false" :items-per-page="100" sort-by="id" @click:row="handleClick">
				<template v-slot:[`item.actions`]="{ item }">
				<v-icon small class="edit" @click="editItem(item)">
					mdi-pencil
				</v-icon>
			</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { VueLoading } from 'vue-loading-template'

export default {


	components: {
		VueLoading
	},
	computed: {
		...mapState("places", ["summaryData"])
	},
	async created() {
		this.loading = true;
		for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
			let item = this.$store.state.places.summaryData[i];
			let index = this.$store.getters.getPlaceIndexById(item.id);
			await this.$store.dispatch("getPlaceParameters", { index: index });
		}
		this.loading = false;
	},
	data() {
		return {
			loading: false,
			headers: [
				{
					text: "管理番号",
					value: "id"
				},
				{
					text: "地区",
					value: "district"
				},
				{
					text: "測定点名",
					value: "name"
				},
				{
					text: "緯度",
					value: "position.lat"
				},
				{
					text: "経度",
					value: "position.lng"
				},
				{
					text: "編集", 
					value: "actions", 
					sortable: false 
				},
			]
		};
	},
	methods: {
		async editItem(item) {
			let index = this.$store.getters.getPlaceIndexById(item.id);
			this.$router
				.push({ name: "AdminPlace", params: { id: index } })
				.catch(() => {});
		},
		handleClick(value, e) {
			// console.log(e.index);
			e.select(!e.isSelected);
		}
	}
};
</script>
<style>
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.loading-spacer {
  height: 30%;
}
</style>
